/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import { isArray } from 'util';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import * as Dropdowns from '../../../../../SharedModules/Dropdowns/dropdowns'
import { withRouter } from 'react-router';
import { Link, Prompt } from 'react-router-dom';
import { CLAIMS_INQUIRY_TCN_LINK } from '../../../../../SharedModules/services/service';
import {
  LinkOfFcn,
  LinkOfTcn
} from '../../../../../SharedModules/TabRedirections/customInputLink';
import { getLongDescription, removeLeadingTrailingSpaces } from '../../../../../SharedModules/CommonUtilities/commonUtilities';
import {
  redirectSystemID,
  redirectGrossLevelFCN
} from '../../../../../SharedModules/TabRedirections/TabRedirections';
import ErrorMessages from '../../../../../SharedModules/Errors/ErrorMessages';
import SuccessMessages from '../../../../../SharedModules/Errors/SuccessMessage';
import * as ErrorConstants from './ErrorConstants';
import moment from 'moment';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  saveARTransfer,
  validatePayeeID,
  resetTransfer
} from '../../../Store/Actions/accountReceivable/ARTransferActions';
import {
  getDropdownDataAction,
  getPayeeIdType,
  getSubDropdownDataAction,
  agingDaysList
} from '../../../Store/Actions/accountReceivable/accountReceivableActions';
import * as ARSearchConstants from '../ARSearch/ARSearchConstants';
import dropdownCriteria from './ARTransfer.json';
import {
  refreshAttachmentsAction
} from '../../../../../SharedModules/store/Actions/SharedAction';
import Footer from '../../../../../SharedModules/Layout/footer';
import {
  getUTCTimeStamp,
  generateUUID,
  getDateInMMDDYYYYFormatWithApendZero
} from '../../../../../SharedModules/DateUtilities/DateUtilities';
import Notes from '../../../../../SharedModules/Notes/Notes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import TabPanel from '../../../../../SharedModules/TabPanel/TabPanel';
import Attachments from '../../../../../SharedModules/Attachments/Attachments';
import numeral from 'numeral';
import SystemPayeeTable from '../../../../../SharedModules/SystemIDTable/SystemPayeeIDTable';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import NativeDropDown from '../../../../../SharedModules/Dropdowns/NativeDropDown'
import { DEFAULT_LOB_VALUE } from '../../../../../SharedModules/AppConstants'

function ARTransfer(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  // NotesTab functionality
  const [value, setValue] = React.useState(0);
  const [tabOne, setTabOne] = React.useState(true);

  const [saveFlag, setSaveFlag] = React.useState(false);
  const [atrError, showATRError] = React.useState(false);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setTabOne(true);
    }
  };
  // Attachments Integration start
  const [attachmentsTableData, setAttachmentsTableData] = React.useState([]);
  const [attachmentFile, setAttachmentFile] = React.useState([]);
  const userInquiryPrivileges = !global.globalIsReadOnly();
  const [headerValuesDesc, setHeaderValuesDesc] = React.useState({
    payeePayerTypeCodeDesc: "",
    payeePayerIDTypeCodeDesc: "",
    activityTypeCodeDesc: "",
    ARReasonCodeDesc: ""
  });
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const [isFocusCheck, setFocusCheck] = React.useState(false);
  React.useEffect(() => {
    if (
      attachmentsTableData &&
      attachmentsTableData.length === 1 &&
      ARTransferDetails
    ) {
      ARTransferDetails.attachmentVO = {
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: getUTCTimeStamp(),
        versionNo: 0,
        dbRecord: true,
        sortColumn: null,
        attachmentSK: null,
        attachmentPageID: null,
        finalPath: null,
        detachInd: false,
        historyIndicator: false,
        cascadeKey: null,
        attachmentIndicator: null,
        dateAdded: getDateInMMDDYYYYFormatWithApendZero(new Date()),
        fileUrl: null,
        showDeleteForSearched: false,
        rowIndex: 0,
        addedBy: logInUserID,
        description:
          attachmentsTableData && attachmentsTableData[0]
            ? attachmentsTableData[0].description
            : null,
        edmsStoredDate: null,
        anEDMSPageId: null,
        fileName:
          attachmentsTableData && attachmentsTableData[0]
            ? attachmentsTableData[0].fileName
            : null,
        file1: null,
        edmsWrkUnitLevel: null,
        edmsDocType: null
      };
    }
  }, [attachmentsTableData]);
  // Attachment Integration ends
  // Notes Integration Begin
  const [notesTableData, setNotesTableData] = React.useState([]);
  const [transferVONotesInput, setTransferVONotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: [],
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({});
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  // add notes
  let notesDataArray = [];

  const addNotes = data => {
    setAllowNavigation(true);
    const noteText = data;
    notesDataArray = notesTableData;

    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
    const notesTransferlist = transferVONotesInput.notesList;
    notesTransferlist.push(noteText);
    setTransferVONotesInput({
      ...transferVONotesInput,
      notesList: notesTransferlist
    });
  };

  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setsaveMessage([]);
    seterrorMessages([]);
    setPayeeTypeError(false);
    setPayeeTypeCodeError(false);
    setPayeeIDError(false);
    setReasonCodeError(false);
  };

  let paramsData;

  let refreshAttachmentResult = useSelector(
    state => state.sharedState.newAttachmentResult
  );

  useEffect(() => {
    if (props.location.state.claimsData) {
      paramsData = props.location.state.claimsData;
      // notes
      if (paramsData.noteSetVO) {
        const noteSetVO = paramsData.noteSetVO;
        const notesTable = paramsData.noteSetVO.notesList;
        setNotesInput({
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: noteSetVO.addedAuditUserID
            ? noteSetVO.addedAuditUserID
            : logInUserID,
          addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
            ? noteSetVO.addedAuditTimeStamp
            : getUTCTimeStamp(),
          versionNo: noteSetVO.versionNo,
          dbRecord: noteSetVO.dbRecord,
          sortColumn: noteSetVO.sortColumn,
          tableName: noteSetVO.tableName,
          noteSetSK: noteSetVO.noteSetSK,
          noteSourceName: noteSetVO.noteSourceName,
          notesList: notesTableData,
          globalNotesList: [],
          checkAll: noteSetVO.checkAll,
          addNewLinkRender: noteSetVO.addNewLinkRender,
          filterLinkRender: noteSetVO.filterLinkRender,
          printLinkRender: noteSetVO.printLinkRender,
          completeNotesList: []
        });
        setNotesTableData(notesTable);
      }
    }
    refreshAttachmentResult = null;
  }, []);

  useEffect(() => {
    if (notesInput) {
      setNoteSetListInput({
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: notesInput.addedAuditUserID
          ? notesInput.addedAuditUserID
          : logInUserID,
        addedAuditTimeStamp: notesInput.addedAuditTimeStamp
          ? notesInput.addedAuditTimeStamp
          : getUTCTimeStamp(),
        versionNo: notesInput.versionNo,
        dbRecord: false,
        sortColumn: null,
        noteTextValue: null,
        userIdName: null,
        notesCexAuditUserID: null,
        notesCexAuditTimeStamp: null,
        notesCexAddedAuditUserID: null,
        notesCexAddedAuditTimeStamp: null,
        noteSetSK: notesInput.noteSetSK,
        usageTypeDesc: '',
        shortNotes: null,
        checked: false,
        renderNoHistoryMsg: false,
        noteSequenceNumber: null,
        currentNote: null,
        rowValue: null,
        usageTypeList: null,
        strBeginDate: moment(new Date()).format('MM/DD/YYYY hh:mm:ss'),
        usageTypeCode: DEFAULT_DD_VALUE,
        tableName: null,
        noteText: '',
        commonEntityName: null,
        commonEntityTypeCode: null,
        commonEntityId: null,
        entityId: null,
        filterbeginDate: moment(new Date()).format('YYYY-MM-DD'),
        filterEndDate: null,
        userId: notesInput.userId ? notesInput.userId : logInUserID,
        noteCexVersionNum: notesInput.noteCexVersionNum
          ? notesInput.noteCexVersionNum
          : 0,
        saNoteSequenceNumber: notesInput.saNoteSequenceNumber
          ? notesInput.saNoteSequenceNumber
          : null,
        notesCexnoteTextValue: notesInput.notesCexnoteTextValue
          ? notesInput.notesCexnoteTextValue
          : 0,
        id: generateUUID()
      });
    }
  }, [notesInput]);

  // Notes Integration End

  const [payeeTypeDropdown, setPayeeTypeDropdown] = React.useState([]);
  const [payeeTypeCodeDropdown, setPayeeTypeCodeDropdown] = React.useState([]);
  const [reasonCodeDetails, setReasonCodeDetails] = React.useState([]);
  const [activityCodeDetails, setActivityCodeDetails] = React.useState([]);
  const [ARletterSent, setARletterSent] = React.useState('');
  const ARTransferDetails = props.location.state.claimsData;
  const [toPayeeName, setToPayeeName] = React.useState('');
  const [toSysID, setToSysID] = React.useState('');
  const [provBillSysId, setProvBillSysId] = React.useState('');
  const dispatch = useDispatch();
  const toPrintRef = useRef();
  const DropDownAPI = dropdownValues => {
    dispatch(getDropdownDataAction(dropdownValues));
  };
  const getReasonCodeMapData = dropdownValues =>
    dispatch(getSubDropdownDataAction(dropdownValues))
  let dropDownDetails = [];
  dropDownDetails = useSelector(
    state => state.accountReceivableState.dropdownData
  );
  const ARletterSentAPI = () => {
    dispatch(agingDaysList());
  };
  const ARLetterSentDetails = useSelector(
    state => state.accountReceivableTransferState.agingDaysList
  );
  const PayeeIDCodeAPI = payee => dispatch(getPayeeIdType(payee));
  let payeeIDTypeCodeDetails = [];
  const PayeeIDCodeAPIFromForm = payee => {
    dispatch(getPayeeIdType(payee));
  };
  let payeeIDTypeCodeDetailsFromForm = [];
  payeeIDTypeCodeDetailsFromForm = useSelector(
    state => state.accountReceivableState.payeeIdTypeDropdown
  );
  payeeIDTypeCodeDetails = useSelector(
    state => state.accountReceivableState.payeeIdTypeDropdown
  );
  const SaveTransferAPI = (values, index) => {
    dispatch(
      saveARTransfer(
        values,
        attachmentsTableData &&
          index >= 0 &&
          attachmentsTableData[index] &&
          attachmentsTableData[index].id
          ? attachmentFile
          : []
      )
    );
  };
  let saveTransferResult = [];
  saveTransferResult = useSelector(
    state => state.accountReceivableTransferState.saveResults
  );
  const validatePayeeIDAPI = values => {
    dispatch(validatePayeeID(values));
  };
  let validateIDResult = [];
  validateIDResult = useSelector(
    state => state.accountReceivableTransferState.validatePayeeIDvalues
  );
  const [toActivityCode, setToActivityCode] = React.useState('');
  let SystemError = useSelector(
    state => state.accountReceivableTransferState.transferError
  );
  const reset = () => {
    dispatch(resetTransfer());
  };
  useEffect(() => {
    SystemError = [];
    saveTransferResult = [];
    validateIDResult = [];
    reset();
    setsaveMessage([]);
    seterrorMessages([]);
    PayeeIDCodeAPIFromForm(ARTransferDetails.payeePayerTypeCode);

    DropDownAPI(dropdownCriteria);
    getReasonCodeMapData(Dropdowns.PAYEE_ID_TYPE_CODE);
    ARletterSentAPI();
  }, []);
  useEffect(() => {
    if (
      payeeIDTypeCodeDetailsFromForm &&
      payeeIDTypeCodeDetailsFromForm.systemFailure === undefined
    ) {
      payeeIDTypeCodeDetailsFromForm.payeeIdTypeCode.map(var1 => {
        if (var1.code === ARTransferDetails.payeePayerIDTypeCode) {
          ARTransferDetails.payeePayerIDTypeCodeDesc = var1.description;
        }
      });
    }
  }, [payeeIDTypeCodeDetailsFromForm]);
  useEffect(() => {
    if (
      payeeIDTypeCodeDetails &&
      payeeIDTypeCodeDetails.payeeIdTypeCode &&
      payeeIDTypeCodeDetails.payeeIdTypeCode.length > 0
    ) {
      setPayeeTypeCodeDropdown(payeeIDTypeCodeDetails.payeeIdTypeCode);
      seterrorMessages([]);
    } else if (
      payeeIDTypeCodeDetails &&
      payeeIDTypeCodeDetails.payeeIdTypeCode &&
      payeeIDTypeCodeDetails.payeeIdTypeCode.length === 1
    ) {
      const tempData = JSON.parse(JSON.stringify(values));
      tempData.payeePayerIDTypeCode = payeeIDTypeCodeDetails.payeeIdTypeCode[0];
      setValues(tempData);
    }
  }, [payeeIDTypeCodeDetails]);
  useEffect(() => {
    if (ARLetterSentDetails && isArray(ARLetterSentDetails)) {
      ARLetterSentDetails.map(var1 => {
        if (ARTransferDetails.agingCode === var1.code) {
          setARletterSent(var1.description);
        }
      });
    }
  }, [ARLetterSentDetails]);
  function updateDropdownDescription (addDropdowns, dropdownKey, valueToMatch, targetProperty) {
    if (addDropdowns && addDropdowns[dropdownKey]) {
      const dropdownData = addDropdowns[dropdownKey].filter((value) => value.code === valueToMatch?.split("-")[0]);
      setHeaderValuesDesc(prevState => ({
        ...prevState,
        [targetProperty]: dropdownData && dropdownData.length > 0
          ? dropdownData[0].description
          : valueToMatch || ""
      }));
    }
  }
  useEffect(() => {
    if (addDropdowns) {
      // Usage for 'payeePayerTypeCodeDesc':
      updateDropdownDescription(addDropdowns, 'G1#G_CMN_ENTY_TY_CD', ARTransferDetails.payeePayerTypeCode, 'payeePayerTypeCodeDesc');
      // Usage for 'payeePayerIDTypeCodeDesc':
      const payeeIdTypeCdDD = {
        P: 'F1#P_ALT_ID_TY_CD',
        M: 'F1#B_ALT_ID_TY_CD',
        O: 'F1#G_ENTY_EXTL_ID_TY_CD',
        TC: 'F1#DM_G_CARR_ENTY_ID_TY_CD'
      };
      const selectedPayeeIdTypeCdDD = payeeIdTypeCdDD[ARTransferDetails.payeePayerTypeCode] || '';
      updateDropdownDescription(addDropdowns, selectedPayeeIdTypeCdDD, ARTransferDetails.payeePayerIDTypeCode, 'payeePayerIDTypeCodeDesc');
      // Usage for 'activityTypeCodeDesc':
      updateDropdownDescription(addDropdowns, 'F1#F_ACTV_TY_CD', ARTransferDetails.activityTypeCode, 'activityTypeCodeDesc');
      // Usage for 'ARReasonCodeDesc':
      updateDropdownDescription(addDropdowns, 'F1#F_RSN_CD', ARTransferDetails.accountReceivableReasonCode, 'ARReasonCodeDesc');
    }
  }, [addDropdowns]);
  useEffect(() => {
    setPayeeTypeCodeDropdown([]);
    if (dropDownDetails && dropDownDetails.listObj) {
      const tempData = JSON.parse(JSON.stringify(values));
      if (dropDownDetails.listObj['General#6002']) {
        if (dropDownDetails.listObj['General#6002'].length === 1) {
          tempData.payeePayerTypeCode =
            dropDownDetails.listObj['General#6002'][0].code;
        }
        setPayeeTypeDropdown(dropDownDetails.listObj['General#6002']);
        dropDownDetails.listObj['General#6002'].map(var1 => {
          if (var1.code === ARTransferDetails.payeePayerTypeCode) {
            ARTransferDetails.payeePayerTypeCodeDesc = var1.description;
          }
        });
      }
      if (dropDownDetails.listObj['Financial#11']) {
        if (dropDownDetails.listObj['Financial#11'].length === 1) {
          tempData.transferReasonCode =
            dropDownDetails.listObj['Financial#11'][0]?.code;
        }
        setReasonCodeDetails(dropDownDetails.listObj['Financial#11']);
      }
      if (dropDownDetails.listObj['Financial#3013']) {
        setActivityCodeDetails(dropDownDetails.listObj['Financial#3013']);
        dropDownDetails.listObj['Financial#3013'].map(var1 => {
          if (var1.code === ARTransferDetails.activityTypeCode) {
            ARTransferDetails.activityTypeCodeDesc = var1.description;
          }
          if (var1.code === 'RT') {
            setToActivityCode(var1.description);
          }
        });
      }
      if (dropDownDetails.listObj['General#1012']) {
        setUsageTypeCodeData(dropDownDetails.listObj['General#1012']);
      }
      if (dropDownDetails.listObj['Financial#6001']) {
        dropDownDetails.listObj['Financial#6001'].map(var2 => {
          if (var2.code === ARTransferDetails.accountReceivableReasonCode) {
            ARTransferDetails.accountReceivableReasonCodeDesc = var2.description;
            ARTransferDetails.accountReceivableReasonCodeLongDesc = var2.longDescription;
          }
        });
      }

      seterrorMessages([]);
      setValues(tempData);
    }
  }, [dropDownDetails]);

  useEffect(() => {
    if (validateIDResult && validateIDResult.SystemError === undefined) {
      if (
        validateIDResult &&
        validateIDResult.respcode &&
        validateIDResult.respcode === '02'
      ) {
        setToPayeeName('');
        setToSysID('');
        setProvBillSysId('');

        seterrorMessages(validateIDResult.errorMessages);
      }
      if (
        validateIDResult &&
        validateIDResult.respcode &&
        validateIDResult.respcode === '01'
      ) {
        if (
          validateIDResult &&
          validateIDResult.enterpriseSearchresultVO &&
          validateIDResult.enterpriseSearchresultVO.searchResults &&
          validateIDResult.enterpriseSearchresultVO.searchResults[0] &&
          validateIDResult.enterpriseSearchresultVO.searchResults[0]
            .multiplePayeeDetails &&
          validateIDResult.enterpriseSearchresultVO.searchResults[0]
            .multiplePayeeDetails.length > 1
        ) {
          setOpenSysPayeeTable(true);
          setSystemPayeeTableData(
            validateIDResult.enterpriseSearchresultVO.searchResults[0]
              .multiplePayeeDetails
          );
          setProvBillSysId(
            validateIDResult.enterpriseSearchresultVO.searchResults[0]
              .provBillSysId
          );
        } else {
          setToPayeeName(
            validateIDResult.enterpriseSearchresultVO.searchResults[0]
              .transferVO.payeePayerNameFromDB
          );
          setToSysID(
            validateIDResult.enterpriseSearchresultVO.searchResults[0]
              .transferVO.sysID
          );
          setProvBillSysId(
            validateIDResult.enterpriseSearchresultVO.searchResults[0]
              .provBillSysId
          );
        }
      }
    } else {
      if (validateIDResult && validateIDResult.SystemError) {
        setToPayeeName('');
        setToSysID('');
        setProvBillSysId('');
        seterrorMessages([ErrorConstants.SYS_ERROR]);
      }
    }
  }, [validateIDResult]);

  useEffect(() => {
    if (refreshAttachmentResult && !refreshAttachmentResult.systemFailure) {
      setAttachmentsTableData(refreshAttachmentResult);
    }
  }, [refreshAttachmentResult]);

  useEffect(() => {
    const saveMSG = [];
    if (
      saveTransferResult &&
      saveTransferResult.respcode &&
      saveTransferResult.respcode === '01'
    ) {
      saveMSG.push(ErrorConstants.SAVE_SUCCESS);
      setsaveMessage(saveMSG);
      seterrorMessages([]);
      setSaveFlag(true);
      setTransferedAmt(
        ARTransferDetails.claimsReceivableDetailsVO.balanceAmount
      );
      dispatch(refreshAttachmentsAction(ARTransferDetails.transferVO.fcn));
      ARTransferDetails.claimsReceivableDetailsVO.balanceAmount = '0.00';
    } else if (
      saveTransferResult &&
      saveTransferResult.respcode &&
      saveTransferResult.respcode !== '01'
    ) {
      seterrorMessages(
        saveTransferResult.errorMessages === null
          ? [ErrorConstants.SYS_ERROR]
          : saveTransferResult.errorMessages
      );
    }
  }, [saveTransferResult]);
  useEffect(() => {
    if (SystemError && SystemError.SystemError) {
      const arconst = [];
      arconst.push(ErrorConstants.SYS_ERROR);
      seterrorMessages(arconst);
    }
  }, [SystemError]);

  const [values, setValues] = React.useState({
    transferReasonCode: DEFAULT_DD_VALUE,
    payeePayerTypeCode: DEFAULT_DD_VALUE,
    payeePayerIDTypeCode: DEFAULT_DD_VALUE,
    payeePayerID: null,
    establishedDateStr: moment().format('L'),
    atrNumber: ''
  });
  const [saveMessage, setsaveMessage] = React.useState([]);
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [payeeTypeError, setPayeeTypeError] = React.useState(false);
  const [payeeTypeCodeError, setPayeeTypeCodeError] = React.useState(false);
  const [payeeIDError, setPayeeIDError] = React.useState(false);
  const [reasonCodeError, setReasonCodeError] = React.useState(false);
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [transferedAmt, setTransferedAmt] = React.useState('0.00');

  // System Payee redirection starts
  const searchSingleFinancialEntityRes = useSelector(
    state => state.financialEntityState.singleSearchResults
  );
  const [redirect, setRedirect] = React.useState(0);
  React.useEffect(() => {
    if (
      redirect === 1 &&
      searchSingleFinancialEntityRes &&
      !searchSingleFinancialEntityRes.systemFailue
    ) {
      props.history.push({
        pathname: '/FinancialEntityUpdate',
        state: { searchSingleFinancialEntityRes }
      });
    }
  }, [searchSingleFinancialEntityRes]);
  // System Payee redirection ends
  const handleChange = name => event => {
    setAllowNavigation(true);

    if (name === 'payeePayerIDTypeCode' && event.target.value !== DEFAULT_DD_VALUE && event.target.value !== '') {
      const payeeIdTypeDesc = fieldDescription(payeeTypeCodeDropdown, event.target.value);
      setPayeeTypeIdCodeDesc(payeeIdTypeDesc);
    }
    if (
      name === 'payeePayerTypeCode' &&
      event.target.value !== DEFAULT_DD_VALUE
    ) {
      const payeeTypeDesc = fieldDescription(payeeTypeDropdown, event.target.value);
      setPayeeTypeDesc(payeeTypeDesc);
      PayeeIDCodeAPI(event.target.value);
      setValues({
        ...values,
        payeePayerTypeCode: event.target.value,
        payeePayerIDTypeCode: DEFAULT_DD_VALUE,
        transferReasonCode: values.transferReasonCode,
        payeePayerID: ''
      });
      setToPayeeName('');
      setToSysID('');

      seterrorMessages([]);
    } else if (
      name === 'payeePayerTypeCode' &&
      event.target.value === DEFAULT_DD_VALUE
    ) {
      setPayeeTypeCodeDropdown([]);
      setValues({
        ...values,
        payeePayerTypeCode: event.target.value,
        payeePayerIDTypeCode: DEFAULT_DD_VALUE,
        transferReasonCode: values.transferReasonCode,
        payeePayerID: ''
      });
      setToPayeeName('');
      setToSysID('');
      seterrorMessages([]);
    } else {
      setValues({ ...values, [name]: event.target.value });
    }
  };

  function masterSave () {
    setAllowNavigation(false);
    const regex = /[^A-Za-z0-9]/;
    const tempErrorMessages = [];
    if (ARTransferDetails.payeePayerID === values.payeePayerID) {
      tempErrorMessages.push(ErrorConstants.SAME_ID);
      seterrorMessages(tempErrorMessages);
    }
    if (ARTransferDetails.sysID === toSysID) {
      tempErrorMessages.push(ErrorConstants.SAME_ID);
      seterrorMessages(tempErrorMessages);
    }
    if (
      Number(ARTransferDetails.claimsReceivableDetailsVO.balanceAmount) === 0
    ) {
      tempErrorMessages.push(ErrorConstants.BALANCE_ZERO);
      seterrorMessages(tempErrorMessages);
    }
    if (values.payeePayerTypeCode === DEFAULT_DD_VALUE) {
      setPayeeTypeError(true);
      tempErrorMessages.push(ErrorConstants.PAYEE_TYPE);
      seterrorMessages(tempErrorMessages);
    } else {
      setPayeeTypeError(false);
    }
    if (values.payeePayerIDTypeCode === DEFAULT_DD_VALUE) {
      setPayeeTypeCodeError(true);
      tempErrorMessages.push(ErrorConstants.PAYEE_ID_TYPE_CODE);
      seterrorMessages(tempErrorMessages);
    } else {
      setPayeeTypeCodeError(false);
    }
    if (values.atrNumber && regex.test(values.atrNumber)) {
      showATRError(true);
      tempErrorMessages.push(`${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${ErrorConstants.TRANSACTION_NUMBER_FORMAT}`);
      seterrorMessages(tempErrorMessages);
    } else (showATRError(false));
    if (
      !values.payeePayerID ||
      values.payeePayerID.trim() === null ||
      values.payeePayerID.trim() === ''
    ) {
      setPayeeIDError(true);
      tempErrorMessages.push(ErrorConstants.PAYEE_ID);
      seterrorMessages(tempErrorMessages);
    } else {
      setPayeeIDError(false);
    }
    if (values.transferReasonCode === DEFAULT_DD_VALUE) {
      setReasonCodeError(true);
      tempErrorMessages.push(ErrorConstants.REASON_CODE);
      seterrorMessages(tempErrorMessages);
    } else {
      setReasonCodeError(false);
    }
    if (tempErrorMessages.length === 0) {
      ARTransferDetails.auditUserID = logInUserID;
      ARTransferDetails.auditTimeStamp = getUTCTimeStamp();
      ARTransferDetails.transferVO.auditUserID = logInUserID;
      ARTransferDetails.transferVO.atrNumber = values?.atrNumber?.toUpperCase();
      ARTransferDetails.transferVO.auditTimeStamp = getUTCTimeStamp();
      ARTransferDetails.transferVO.addedAuditUserID = logInUserID;
      ARTransferDetails.transferVO.addedAuditTimeStamp = getUTCTimeStamp();
      ARTransferDetails.transferVO.transferReasonCode =
        values.transferReasonCode;
      ARTransferDetails.transferVO.payeePayerTypeCode =
        values.payeePayerTypeCode;
      ARTransferDetails.transferVO.payeePayerIDTypeCode =
        values.payeePayerIDTypeCode;
      ARTransferDetails.transferVO.payeePayerID = values.payeePayerID;
      ARTransferDetails.transferVO.payeePayerIdFromDB = toSysID;
      ARTransferDetails.transferVO.sysID = toSysID;
      ARTransferDetails.provBillSysId = provBillSysId;
      if (ARTransferDetails.noteSetVO) {
        const noteSK = ARTransferDetails.noteSetVO.noteSetSK;
        ARTransferDetails.noteSetVO = transferVONotesInput;
        ARTransferDetails.noteSetVO.noteSetSK = noteSK;
      } else {
        ARTransferDetails.noteSetVO = transferVONotesInput;
      }
      const index = attachmentsTableData.length - 1;
      const attachmentVOList = [];
      if (
        attachmentsTableData &&
        index >= 0 &&
        attachmentsTableData[index] &&
        attachmentsTableData[index].id
      ) {
        attachmentsTableData.map(value => {
          if (value.id) {
            const attachmentVO = {
              auditUserID: logInUserID,
              auditTimeStamp: getUTCTimeStamp(),
              addedAuditUserID: logInUserID,
              addedAuditTimeStamp: getUTCTimeStamp(),
              versionNo: 0,
              dbRecord: true,
              sortColumn: null,
              attachmentSK: null,
              attachmentPageID: null,
              finalPath: null,
              detachInd: false,
              historyIndicator: false,
              cascadeKey: null,
              attachmentIndicator: null,
              dateAdded: getDateInMMDDYYYYFormatWithApendZero(
                new Date(
                  new Date().toLocaleString('en-US', {
                    timeZone: process.env.REACT_APP_TIMEZONE
                  })
                )
              ),
              fileUrl: null,
              showDeleteForSearched: false,
              rowIndex: 0,
              addedBy: logInUserID,
              description: value && value ? value.description : null,
              edmsStoredDate: null,
              anEDMSPageId: null,
              fileName: attachmentsTableData && value ? value.fileName : null,
              file1: null,
              edmsWrkUnitLevel: null,
              edmsDocType: null
            };
            attachmentVOList.push(removeLeadingTrailingSpaces(attachmentVO));
          }
        });
      }
      ARTransferDetails.attachmentVO = null;
      ARTransferDetails.attachmentsList = attachmentVOList;
      ARTransferDetails.transferVO = removeLeadingTrailingSpaces(ARTransferDetails.transferVO);
      SaveTransferAPI(ARTransferDetails, index);
      seterrorMessages([]);
    }
  }

  function validate () {
    const tempIDErrorMessages = [];
    if (ARTransferDetails.payeePayerID === values.payeePayerID) {
      tempIDErrorMessages.push(ErrorConstants.SAME_ID);
      seterrorMessages(tempIDErrorMessages);
    }
    if (values.payeePayerTypeCode === DEFAULT_DD_VALUE) {
      setPayeeTypeError(true);
      tempIDErrorMessages.push(ErrorConstants.PAYEE_TYPE);
      seterrorMessages(tempIDErrorMessages);
    } else {
      setPayeeTypeError(false);
    }
    if (values.payeePayerIDTypeCode === DEFAULT_DD_VALUE) {
      setPayeeTypeCodeError(true);
      tempIDErrorMessages.push(ErrorConstants.PAYEE_ID_TYPE_CODE);
      seterrorMessages(tempIDErrorMessages);
    } else {
      setPayeeTypeCodeError(false);
    }
    if (
      (values.payeePayerID && values.payeePayerID.trim()) === null ||
      (values.payeePayerID && values.payeePayerID.trim()) === ''
    ) {
      setPayeeIDError(true);
      tempIDErrorMessages.push(ErrorConstants.PAYEE_ID);
      seterrorMessages(tempIDErrorMessages);
    } else {
      setPayeeIDError(false);
    }
    if (tempIDErrorMessages.length === 0) {
      ARTransferDetails.transferVO.payeePayerTypeCode =
        values.payeePayerTypeCode;
      ARTransferDetails.transferVO.payeePayerIDTypeCode =
        values.payeePayerIDTypeCode;
      ARTransferDetails.transferVO.payeePayerID = values.payeePayerID;
      ARTransferDetails.transferVO = removeLeadingTrailingSpaces(ARTransferDetails.transferVO);
      ARTransferDetails.lob = DEFAULT_LOB_VALUE;
      validatePayeeIDAPI(ARTransferDetails);
      seterrorMessages([]);
    }
  }
  // Payee Popup Implementation Starts
  const [systemPayeeTableData, setSystemPayeeTableData] = React.useState([]);
  const [currentSysID, setCurrentSysID] = React.useState(null);
  const [openSysPayeeTable, setOpenSysPayeeTable] = React.useState(false);
  const [payeeTypeDesc, setPayeeTypeDesc] = React.useState('');
  const [payeeTypeIdCodeDesc, setPayeeTypeIdCodeDesc] = React.useState('');
  const fieldDescription = (dropdownFilter, Code) => {
    const filteredValue = dropdownFilter.filter(
      (rCode) => rCode.code === Code
    );
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].description;
    }
    return Code;
  };
  const [canCel, setCancel] = React.useState(false);
  const handleCloseSysPayeeTable = () => {
    setCancel(true);
    setOpenSysPayeeTable(false);
    setValues({
      ...values,
      payeePayerID: ''
    });
    setCurrentSysID(null);
  };
  useEffect(() => {
    if (inputRefCursor.current && canCel) {
      inputRefCursor.current.focus();
    }
  }, [canCel]);
  const onRadioButtonClick = row => {
    setCurrentSysID(row);
  };
  const onContinue = () => {
    if (currentSysID) {
      setToPayeeName(currentSysID.payeeName);
      setToSysID(currentSysID.sysID);
      setCurrentSysID(null);
      setOpenSysPayeeTable(false);
    }
  };
  // Payee popup Implementation ends
  /* This function is used to navigate to Accounts Receivable Add Screen */

  const inputRefCursor = useRef(null);
  return (
    <div>
      <div>
        <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />

        <div className='tabs-container' ref={toPrintRef}>
          <ErrorMessages errorMessages={errorMessages} />
          {saveMessage.length > 0 && errorMessages.length === 0 ? (
            <SuccessMessages successMessages={saveMessage} />
          ) : null}
          <div className='tab-header'>
            <h1 className='tab-heading float-left'>
              Account Receivable Transfer
            </h1>
            <div className='float-right mt-2'>
              <Button className='btn btn-success' onClick={masterSave} data-testid='master_save'>
                <i className='fa fa-check' aria-hidden='true'></i>
                Save
              </Button>
            </div>
            <div className='clearfix'></div>
          </div>
          <div className='tab-body'>
            <div className='tab-holder my-3'>
              <form autoComplete='off'>
                <div className='form-wrapper'>
                  <div className='mui-custom-form input-md'>
                    <TextField
                      id='standard-originalamt'
                      fullWidth
                      label='Original A/R Amount'
                      value={
                        ARTransferDetails.claimsReceivableDetailsVO
                          .originalAmount
                      }
                      inputProps={{ maxLength: 13 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>$</InputAdornment>
                        ),
                        readOnly: true,
                        className: 'Mui-disabled'
                      }}
                    />
                  </div>
                  <div className='mui-custom-form input-md'>
                    <TextField
                      id='standard-adjustedamt1'
                      fullWidth
                      label='Adjusted A/R Amount'
                      value={
                        ARTransferDetails.claimsReceivableDetailsVO
                          .adjustedArAmt
                      }
                      inputProps={{ maxLength: 13 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>$</InputAdornment>
                        ),
                        readOnly: true,
                        className: 'Mui-disabled'
                      }}
                    />
                  </div>
                  <div className='mui-custom-form input-md'>
                    <TextField
                      id='standard-penalty1'
                      fullWidth
                      label='Penalty'
                      value={ARTransferDetails.calculatedPenalty}
                      inputProps={{ maxLength: 15 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>$</InputAdornment>
                        ),
                        readOnly: true,
                        className: 'Mui-disabled'
                      }}
                    />
                  </div>
                  <div className='mui-custom-form input-md'>
                    <TextField
                      id='standard-fees'
                      fullWidth
                      label='Fees'
                      value={ARTransferDetails.calculatedFees}
                      inputProps={{ maxLength: 15 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>$</InputAdornment>
                        ),
                        readOnly: true,
                        className: 'Mui-disabled'
                      }}
                    />
                  </div>
                </div>
                <div className='form-wrapper'>
                  <div className='mui-custom-form input-md'>
                    <TextField
                      id='standard-interest1'
                      fullWidth
                      label='Interest'
                      value={ARTransferDetails.calculatedInterest}
                      inputProps={{ maxLength: 15 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>$</InputAdornment>
                        ),
                        readOnly: true,
                        className: 'Mui-disabled'
                      }}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <fieldset className='custom-fieldset'>
                      <legend>Transfer From:</legend>
                      <div className='form-wrapper'>
                        <div className='mui-custom-form mui-custom-form-content2 override-width-47'>
                          <TextField
                            id='standard-payeetype1'
                            fullWidth
                            label='Payee Type'
                            value={headerValuesDesc.payeePayerTypeCodeDesc || ""}
                            inputProps={{ maxLength: 3 }}
                            InputProps={{
                              readOnly: true,
                              className: 'Mui-disabled'
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </div>
                        <div className='mui-custom-form mui-custom-form-content2 override-width-47'>
                          <TextField
                            id='standard-payeeidtype1'
                            fullWidth
                            label='Payee ID Type Code'
                            value={headerValuesDesc.payeePayerIDTypeCodeDesc || ""}
                            inputProps={{ maxLength: 3 }}
                            InputProps={{
                              readOnly: true,
                              className: 'Mui-disabled'
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </div>
                      </div>
                      <div className='form-wrapper'>
                        <div className='mui-custom-form mui-custom-form-content2 override-width-47'>
                          <TextField
                            id='standard-payeeid1'
                            fullWidth
                            label='Payee ID'
                            value={ARTransferDetails.payeePayerID}
                            inputProps={{ maxLength: 15 }}
                            InputProps={{
                              readOnly: true,
                              className: 'Mui-disabled'
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </div>
                        <div className='mui-custom-form mui-custom-form-content2 override-width-47'>
                          <TextField
                            id='standard-payeename1'
                            fullWidth
                            label='Payee Name'
                            value={ARTransferDetails.payeePayerNameFromDB}
                            InputProps={{
                              readOnly: true,
                              className: 'Mui-disabled'
                            }}
                            inputProps={{ maxLength: 60 }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </div>
                      </div>
                      <div className='form-wrapper'>
                        <div className='mui-custom-form mui-custom-form-content2 override-width-47 has-link'>
                          <TextField
                            id='standard-systempayeeid1'
                            fullWidth
                            InputProps={{
                              readOnly: true,
                              className: 'Mui-disabled',
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <Link
                                    to={redirectSystemID(
                                      ARTransferDetails.sysID
                                    )} target='_blank'
                                  >
                                    {ARTransferDetails.sysID}
                                  </Link>
                                </InputAdornment>
                              )
                            }}
                            label='System Payee ID'
                            inputProps={{ maxLength: 10 }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </div>
                        <div className='mui-custom-form mui-custom-form-content2 override-width-47 has-link'>
                          <TextField
                            id='standard-fcn1'
                            fullWidth
                            label='FCN'
                            InputProps={{
                              readOnly: true,
                              className: 'Mui-disabled',
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <Link
                                    to={redirectGrossLevelFCN(
                                      ARTransferDetails.receivableFCN
                                    )}
                                    target='_blank'
                                  >
                                    {ARTransferDetails.receivableFCN}
                                  </Link>
                                </InputAdornment>
                              )
                            }}
                            inputProps={{ maxLength: 14 }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </div>
                      </div>
                      <div className='form-wrapper'>
                        <div className='mui-custom-form mui-custom-form-content2 override-width-47'>
                          <TextField
                            id='standard-ARTransferreasoncode1'
                            fullWidth
                            label='Reason Code'
                            value={headerValuesDesc.ARReasonCodeDesc || ""}
                            title={ARTransferDetails.accountReceivableReasonCodeLongDesc ? ARTransferDetails.accountReceivableReasonCodeLongDesc : ARTransferDetails.accountReceivableReasonCodeDesc || ARTransferDetails.accountReceivableReasonCode}
                            inputProps={{ maxLength: 3 }}
                            InputProps={{
                              readOnly: true,
                              className: 'Mui-disabled'
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </div>
                        <div className="mui-custom-form mui-custom-form-content2 override-width-47 has-link">
                          <TextField
                            id="standard-fttcn1"
                            fullWidth
                            label="Related FT TCN"
                            inputProps={{ maxLength: 17 }}
                            InputProps={{
                              readOnly: true,
                              className: 'Mui-disabled',
                              startAdornment: (
                                <InputAdornment position="start">
                                  <a href={CLAIMS_INQUIRY_TCN_LINK + ARTransferDetails.relatedFTTCN} target='_blank' rel="noreferrer">
                                    {ARTransferDetails.relatedFTTCN}
                                  </a>
                                </InputAdornment>
                              )
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </div>
                      </div>
                      <div className='form-wrapper'>
                        <div className='mui-custom-form mui-custom-form-content2 override-width-47'>
                          <TextField
                            id='standard-lettersent1'
                            fullWidth
                            InputProps={{
                              readOnly: true,
                              className: 'Mui-disabled'
                            }}
                            label='A/R Letter Sent'
                            value={ARletterSent}
                            placeholder=''
                            inputProps={{ maxLength: 3 }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <div className='mui-custom-form mui-custom-form-content2 with-date override-width-47'>
                            <KeyboardDatePicker
                              maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                              id='standard-estdate'
                              label='Established Date'
                              format='MM/dd/yyyy'
                              value={ARTransferDetails.establishedDate}
                              disabled={true}
                              InputProps={{
                                readOnly: true,
                                className: 'Mui-disabled'
                              }}
                              KeyboardButtonProps={{
                                'aria-label': 'change date'
                              }}
                            />
                          </div>
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className='form-wrapper'>
                        <div className='mui-custom-form mui-custom-form-content2  with-icon with-icon-margin override-width-47'>
                          <TextField
                            id='standard-ArTransferbalanceamt'
                            fullWidth
                            label='Balance Amount'
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  $
                                </InputAdornment>
                              ),
                              readOnly: true,
                              className: 'Mui-disabled'
                            }}
                            value={
                              ARTransferDetails.claimsReceivableDetailsVO
                                .balanceAmount
                                ? numeral(
                                  ARTransferDetails.claimsReceivableDetailsVO
                                    .balanceAmount
                                ).format('0,0.00')
                                : ARTransferDetails.claimsReceivableDetailsVO
                                  .balanceAmount
                            }
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </div>
                        <div className='mui-custom-form mui-custom-form-content2 override-width-47'>
                          <TextField
                            id='standard-activityTypeCode'
                            fullWidth
                            label='Activity Type Code'
                            InputProps={{
                              readOnly: true,
                              className: 'Mui-disabled'
                            }}
                            value={headerValuesDesc.activityTypeCodeDesc || ""}
                            inputProps={{ maxLength: 3 }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </div>
                      </div>
                      <div className='form-wrapper'>
                        <div className='mui-custom-form mui-custom-form-content2  with-icon with-icon-margin override-width-47'>
                          <TextField
                            id='FromATRnumber'
                            fullWidth
                            label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
                            value={ARTransferDetails.atrNumber}
                            placeholder=""
                            InputProps={{
                              readOnly: true,
                              className: 'Mui-disabled'
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          ></TextField>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className='col-6'>
                    <fieldset className='custom-fieldset'>
                      <legend>Transfer To:</legend>
                      <div className='form-wrapper'>
                        <NativeDropDown
                          isRequired
                          className='override-width-47 mui-custom-form-content2'
                          id='payeePayerTypeCode'
                          label='Payee Type'
                          inputProps={{ 'data-testid': 'payee_type' }}
                          value={values.payeePayerTypeCode}
                          onChange={handleChange('payeePayerTypeCode')}
                          errTxt={
                            payeeTypeError ? ErrorConstants.PAYEE_TYPE : null
                          }
                          dropdownList={payeeTypeDropdown.map(option => (
                            <option key={option.code} value={option.code}>
                              {option.description}
                            </option>
                          ))}
                        />
                        <NativeDropDown
                          isRequired
                          className='override-width-47 mui-custom-form-content2'
                          id='payeePayerIDTypeCode'
                          label='Payee ID Type Code'
                          inputProps={{ 'data-testid': 'payee_type_ID' }}
                          value={values.payeePayerIDTypeCode}
                          disabled={
                            values.payeePayerTypeCode === DEFAULT_DD_VALUE
                          }
                          onChange={handleChange('payeePayerIDTypeCode')}
                          errTxt={
                            payeeTypeCodeError
                              ? ErrorConstants.PAYEE_ID_TYPE_CODE
                              : null
                          }
                          dropdownList={payeeTypeCodeDropdown.map(option => (
                            <option key={option.code} value={option.code}>
                              {option.description}
                            </option>
                          ))}
                        />
                      </div>
                      <div className='form-wrapper'>
                        <div className='mui-custom-form mui-custom-form-content2 override-width-47'>
                          <TextField
                            id='standard-payeeid-1'
                            fullWidth
                            required
                            label='Payee ID'
                            inputRef={inputRefCursor
                            }
                            value={values.payeePayerID}
                            onChange={handleChange('payeePayerID')}
                            onBlur={validate}
                            inputProps={{ maxlength: 15, "data-testid": 'test_Payee_ID' }}
                            placeholder=''
                            InputLabelProps={{
                              shrink: true
                            }}
                            error={
                              payeeIDError ? ErrorConstants.PAYEE_ID : null
                            }
                            helperText={
                              payeeIDError ? ErrorConstants.PAYEE_ID : null
                            }
                          ></TextField>
                        </div>
                        <div className='mui-custom-form mui-custom-form-content2 override-width-47'>
                          <TextField
                            id='standard-payeename'
                            fullWidth
                            label='Payee Name'
                            value={toPayeeName}
                            InputProps={{
                              readOnly: true,
                              className: 'Mui-disabled'
                            }}
                            inputProps={{ maxLength: 60 }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </div>
                      </div>
                      <div className='form-wrapper'>
                        <div
                          className={
                            toSysID !== ''
                              ? 'mui-custom-form mui-custom-form-content2 override-width-47 has-fcn-link'
                              : 'mui-custom-form mui-custom-form-content2 override-width-47'
                          }
                        >
                          <TextField
                            id='standard-systempayeeid'
                            fullWidth
                            label='System Payee ID'
                            value={toSysID !== '' ? null : toSysID}
                            InputProps={{
                              readOnly: true,
                              className: 'Mui-disabled ',
                              inputComponent: LinkOfFcn,
                              inputProps: {
                                value: toSysID,
                                redirection: redirectSystemID,
                                saveFlag: toSysID !== ''
                              }
                            }}
                            inputProps={{ maxLength: 10 }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </div>
                        <div
                          className={
                            saveFlag
                              ? 'mui-custom-form mui-custom-form-content2 override-width-47 has-fcn-link'
                              : 'mui-custom-form mui-custom-form-content2 override-width-47'
                          }
                        >
                          <TextField
                            id='standard-fcn'
                            fullWidth
                            label='FCN'
                            InputProps={{
                              readOnly: true,
                              className: 'Mui-disabled',
                              inputComponent: LinkOfFcn,
                              inputProps: {
                                value: ARTransferDetails.transferVO.fcn,
                                redirection: redirectGrossLevelFCN,
                                saveFlag: saveFlag
                              }
                            }}
                            inputProps={{ maxLength: 14 }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </div>
                      </div>
                      <div className='form-wrapper'>
                        <NativeDropDown
                          id='standard-reasoncode1'
                          className='override-width-47 mui-custom-form-content2'
                          isRequired
                          label='Reason Code'
                          value={values.transferReasonCode}
                          onChange={handleChange('transferReasonCode')}
                          inputProps={{ maxLength: 3, "data-testid": "reason_code" }}
                          errTxt={
                            reasonCodeError
                              ? ErrorConstants.REASON_CODE
                              : null
                          }
                          title={getLongDescription(reasonCodeDetails, values.transferReasonCode)}
                          dropdownList={reasonCodeDetails.map(option => (
                            <option key={option.code} value={option.code}>
                              {option.description}
                            </option>
                          ))}
                        />
                        <div className='mui-custom-form mui-custom-form-content2 override-width-47 has-link'>
                          <TextField
                            id='standard-fttcn'
                            fullWidth
                            label='Related FT TCN'
                            inputProps={{ maxLength: 17 }}
                            InputProps={{
                              readOnly: true,
                              className: 'Mui-disabled',
                              inputComponent: LinkOfTcn,
                              inputProps: { value: ARTransferDetails.transferVO.relatedFTTCN, saveFlag: saveFlag }
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </div>
                      </div>
                      <div className='form-wrapper'>
                        <div className='mui-custom-form mui-custom-form-content2 override-width-47'>
                          <TextField
                            id='standard-lettersent'
                            fullWidth
                            InputProps={{
                              readOnly: true,
                              className: 'Mui-disabled'
                            }}
                            label='A/R Letter Sent'
                            value={ARletterSent}
                            placeholder=''
                            inputProps={{ maxLength: 3 }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <div className='mui-custom-form mui-custom-form-content2 with-date override-width-47'>
                            <KeyboardDatePicker
                              maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                              id='standard-ARTransferestdate'
                              label='Established Date'
                              format='MM/dd/yyyy'
                              value={values.establishedDateStr}
                              disabled={true}
                              InputProps={{
                                readOnly: true,
                                className: 'Mui-disabled'
                              }}
                              KeyboardButtonProps={{
                                'aria-label': 'change date'
                              }}
                            />
                          </div>
                        </MuiPickersUtilsProvider>
                        <div className='form-wrapper'>
                          <div className='mui-custom-form mui-custom-form-content2  with-icon with-icon-margin override-width-47'>
                            <TextField
                              id='standard-ARTransferbalanceamt1'
                              fullWidth
                              label='Balance Amount'
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position='start'>
                                    $
                                  </InputAdornment>
                                ),
                                readOnly: true,
                                className: 'Mui-disabled'
                              }}
                              value={
                                transferedAmt
                                  ? numeral(transferedAmt).format('0,0.00')
                                  : transferedAmt
                              }
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </div>
                          <div className='mui-custom-form mui-custom-form-content2 input-md override-width-47'>
                            <TextField
                              id='standard-ARTransferactivityTypeCode'
                              fullWidth
                              label='Activity Type Code'
                              InputProps={{
                                readOnly: true,
                                className: 'Mui-disabled'
                              }}
                              value={headerValuesDesc.activityTypeCodeDesc || ""}
                              inputProps={{ maxLength: 3 }}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='form-wrapper'>
                        <div className='mui-custom-form mui-custom-form-content2  with-icon with-icon-margin override-width-47'>
                          <TextField
                            id='ToATRnumber'
                            fullWidth
                            label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
                            placeholder=""
                            value={values.atrNumber}
                            onChange={handleChange('atrNumber')}
                            inputProps={{ maxlength: 18 }}
                            InputLabelProps={{
                              shrink: true
                            }}
                            helperText={atrError ? `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${ErrorConstants.TRANSACTION_NUMBER_FORMAT}` : null}
                            error={atrError ? `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${ErrorConstants.TRANSACTION_NUMBER_FORMAT}` : null}
                          ></TextField>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </form>
            </div>
            <div className='tab-panelbody'>
              <div className='tab-holder mb-3 mt-2'>
                <AppBar position='static'>
                  <Tabs
                    value={value}
                    onChange={handleChangeTabs}
                    aria-label='simple tabs example'
                  >
                    <Tab label='Notes' />
                    <Tab label='Attachments' />
                  </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                  <div className='tab-holder p-0'>
                    <Notes
                      addNotes={addNotes}
                      notesTableData={notesTableData}
                      setFocusCheck={setFocusCheck}
                      noteSetListInput={noteSetListInput}
                      setNoteSetListInput={setNoteSetListInput}
                      usageTypeCodeData={usageTypeCodeData}
                      editNoteData={editNoteData}
                      setEditNoteData={setEditNoteData}
                      setSuccessMessages={clearSuccessErrorMsgs}
                    />
                  </div>
                </TabPanel>
                <TabPanel
                  value={value}
                  index={1}
                >
                  <div className='tab-holder p-0'>
                    <Attachments
                      userInquiryPrivileges={userInquiryPrivileges}
                      attachmentFile={attachmentFile}
                      setAttachmentFile={setAttachmentFile}
                      attachmentsTableData={attachmentsTableData}
                      setAttachmentsTableData={setAttachmentsTableData}
                      parentErrorMesage={seterrorMessages}
                      setAllowNavigation={setAllowNavigation}
                      setSystemSuccesMessages={clearSuccessErrorMsgs}
                      setFocusCheck={setFocusCheck}
                    />
                  </div>
                </TabPanel>
              </div>
            </div>
          </div>
          <Footer print />
        </div>
      </div>
      <SystemPayeeTable
        payeeType={payeeTypeDesc}
        payeeIdTypeCode={payeeTypeIdCodeDesc}
        payeeId={values.payeePayerID}
        openSysPayeeTable={openSysPayeeTable}
        handleCloseSysPayeeTable={handleCloseSysPayeeTable}
        uniqueKey={'sysID'}
        onTableCellButtonClick={onRadioButtonClick}
        tableData={systemPayeeTableData}
        currentSysID={currentSysID}
        onContinue={onContinue}
      />
    </div>
  );
}
export default withRouter(ARTransfer);
